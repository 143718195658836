import * as React from "react"
import {graphql, Link} from "gatsby";
import { render } from 'datocms-structured-text-to-html-string';
import styled from 'styled-components';
import { Disqus } from 'gatsby-plugin-disqus';

import StaticPageTemplate from "./../components/staticPageTemplate"

import SEO from './../components/SEO';

const Date = styled.div`
    margin-bottom: 25px;
    color: #B8B6B9;
    font-size: 1.15em;
`

const BackButton = styled.div`
    font-family: "Lato", sans-serif;
    font-size: 1.1em;
    transition: color .4s;
    color: #363537;
    margin: 30px 0px;

    &:hover{
        color: #FF7171 !importnant;
    }
`

const HorizontalLine = styled.hr`
    margin-top: 80px;
    margin-bottom: 80px;
`

const ArticleTemplate = ({data: {article}}) => {
  return (
    <StaticPageTemplate title={article.title}>
        <SEO title={article.title} link={"https://debica-dzieciutracone.pl/artykuly/"+article.slug} ogTitle={article.title}/>


        <Date>{article.date}</Date>
       <div className="DatoCMS" dangerouslySetInnerHTML={{__html: render(article.body.value)}}></div>
       <BackButton><Link to="/">Powrót do strony główej</Link></BackButton>

        <HorizontalLine/>

       <Disqus
        config={{
            /* Replace PAGE_URL with your post's canonical URL variable */
            url: "https://debica-dzieciutracone.pl/artykuly/"+article.slug,
            /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
            identifier: article.slug,
            /* Replace PAGE_TITLE with the title of the page */
            title: article.title
            }}
        />
    </StaticPageTemplate>
  )
}

export default ArticleTemplate

export const query = graphql`
    query fetchArticle($slug: String) {
        article: datoCmsArticle(slug: {eq: $slug}){
            title,
            body{
                value
            },
            slug,
            date
        }
    }
`